<template>
  <div
    v-if="
      ($store.state.fastPermissoes.administrador == 'S' ||
        $store.state.fastPermissoes.tesouraria == 'S') &&
      $store.state.fastPermissoes.ativo == 'S' &&
      $store.state.fastPlataformaModulos.modulo_secretaria
    "
  >
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
                >Minhas Plataformas</a
              >
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                href="#"
                @click.prevent="
                  $router.push('/plataforma/' + $route.params.id_plataforma)
                "
                >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a
              >
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                href="#"
                @click.prevent="
                  $router.push(
                    '/plataforma/' + $route.params.id_plataforma + '/tesouraria'
                  )
                "
                >Modo tesouraria</a
              >
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a class="aluno_font_color" href="#"
                >Relatórios - Cobranças Whatsapp</a
              >
            </li>
          </ul>
        </nav>

        <!-- /breadcrumb -->
      </div>
      <div class="fd-mode-adm-content mt-5 container">
        <!-- Conteúdo Principal Relatório -->
        <transition name="slide-fade">
          <div>
            <section class="fd-app-welcome text-left">
              <h2><b>Relatório Cobranças (Whatsapp)</b></h2>
              <img
                class="mb-3"
                :src="require('@/assets/images/form-effect-01.png')"
                style="max-height: 16px"
              />
            </section>
            <div class="d-flex">
              <div
                v-if="fastCobrancaRel.filtroEscolhido.IdsPlataformas.length"
                class="align-self-start mr-auto mb-2"
              >
                <button
                  v-for="plataforma in fastCobrancaRel.filtroEscolhido
                    .IdsPlataformas"
                  :key="plataforma.id"
                  @click="removePlataformaFiltro(plataforma.id)"
                  type="button"
                  class="btn btn-sm btn-outline-secondary text-capitalize rounded-pill mr-2 mb-2"
                >
                  Plataforma:
                  {{ plataforma.nome }}
                  <b-icon icon="x-lg" font-scale="0.7" class="mb-1"></b-icon>
                </button>
              </div>
            </div>
            <section>
              <div class="row d-flex align-items-end">
                <form class="form col-sm-12 col-md-12 col-lg-4 mt-4 mb-3">
                  <label class="text-secondary mb-0 mt-2"
                    ><small>Plataforma:</small></label
                  >
                  <select
                    class="form-control form-control-sm"
                    v-model="fastCobrancaRel.filtroEscolhido.idPlataforma"
                    @change="adicionaPlataformaFiltro()"
                  >
                    <option value="">Selecione</option>
                    <option value="todas">Todas</option>
                    <option
                      v-for="plataforma in fastCobrancaRel.filtros.plataformas"
                      :key="plataforma.id_plataforma"
                      :value="plataforma"
                    >
                      {{ plataforma.nome_plataforma }}
                    </option>
                  </select>
                </form>
                <div class="text-left col-lg-4 col-md-12 col-sm-12 mt-4 mb-3">
                  <label class="text-secondary mb-0 mx-1 row">
                    <small class="pl-0 ml-0 pl-0 col-8"
                      ><b>Vencimento:</b></small
                    >
                    <small
                      class="col-4 text-right mr-0 pr-0"
                      role="button"
                      v-if="
                        fastCobrancaRel.filtroEscolhido.dataVencimentoStart ||
                        fastCobrancaRel.filtroEscolhido.dataVencimentoEnd
                      "
                      @click="
                        (fastCobrancaRel.filtroEscolhido.dataVencimentoStart =
                          ''),
                          (fastCobrancaRel.filtroEscolhido.dataVencimentoEnd =
                            '')
                      "
                      >Limpar
                      <b-icon
                        icon="x-circle"
                        scale="1.1"
                        variant="danger"
                      ></b-icon
                    ></small>
                  </label>
                  <div class="d-flex">
                    <b-form-datepicker
                      class="align-self-start"
                      size="sm"
                      v-model="
                        fastCobrancaRel.filtroEscolhido.dataVencimentoStart
                      "
                      @input="validaDataFiltro('I')"
                      locale="pt-BR"
                      :date-format-options="{
                        year: 'numeric',
                        month: 'short',
                        day: '2-digit',
                      }"
                      label-no-date-selected="Selecionar data"
                      placeholder="Selecionar data"
                      label-help="Use o cursor para selecionar"
                    ></b-form-datepicker>
                    <small class="align-self-start pt-1 px-2">até</small>
                    <b-form-datepicker
                      class="align-self-start"
                      size="sm"
                      v-model="
                        fastCobrancaRel.filtroEscolhido.dataVencimentoEnd
                      "
                      @input="validaDataFiltro('F')"
                      locale="pt-BR"
                      :date-format-options="{
                        year: 'numeric',
                        month: 'short',
                        day: '2-digit',
                      }"
                      label-no-date-selected="Selecionar data"
                      placeholder="Selecionar data"
                      label-help="Use o cursor para selecionar"
                    ></b-form-datepicker>
                  </div>
                </div>
                <div class="text-right col-lg-4 col-md-12 col-sm-12 mb-3 mt-5">
                  <b-button
                    @click="exportaPlanilha()"
                    variant="outline-primary btn-sm text-capitalize rounded mr-3"
                    >Exportar</b-button
                  >
                  <b-button
                    @click="limpaFiltros()"
                    variant="custom-outline-primary btn-sm text-capitalize rounded mr-3"
                    >Limpar Filtros</b-button
                  >
                  <b-button
                    @click="
                      (fastCobrancaRel.PageIndex = 1),
                        (fastCobrancaRel.RangeStart = 0),
                        (fastCobrancaRel.RangeEnd = 0),
                        (fastCobrancaRel.TotalResults = 0),
                        getCobrancasRel()
                    "
                    variant="custom-primary btn-sm text-capitalize rounded"
                    >Filtrar</b-button
                  >
                </div>
              </div>
            </section>

            <div class="row">
              <div class="col-12 table-responsive">
                <table class="table table-sm text-nowrap table-hover">
                  <thead class="thead-dark" v-if="fastCobrancaRel.loading">
                    <tr>
                      <th colspan="12" class="text-center">
                        <b-icon icon="gear-fill" animation="spin" /> Carregando
                      </th>
                    </tr>
                  </thead>
                  <thead class="thead-dark" v-if="!fastCobrancaRel.loading">
                    <tr>
                      <th class="text-center">
                        <small>#</small>
                      </th>
                      <th class="text-center">
                        <small>Nome</small>
                      </th>
                      <th class="text-center">
                        <small>Responsável</small>
                      </th>
                      <th class="text-center">
                        <small>Valor da Parcela</small>
                      </th>
                      <th class="text-center">
                        <small>Data de Vencimento</small>
                      </th>
                      <th class="text-center">
                        <small>Plataforma</small>
                      </th>
                      <th class="text-center">
                        <small>Telefone</small>
                      </th>
                      <th class="text-center">
                        <small>Boleto</small>
                      </th>
                    </tr>
                  </thead>
                  <tbody v-if="fastCobrancaRel.loading">
                    <tr>
                      <td colspan="12" class="text-center">
                        <b-icon icon="gear-fill" animation="spin" /> Carregando
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else-if="this.fastCobrancaRel.filtro.length">
                    <tr
                      v-for="(e, index) in this.fastCobrancaRel.filtro"
                      :key="index"
                    >
                      <td class="text-center mx-2">
                        {{ e.row_number }}
                      </td>
                      <td class="text-center">
                        {{ e.nome_aluno }}
                      </td>
                      <td class="text-center">
                        {{ e.nome_responsavel }}
                      </td>
                      <td class="text-center">
                        {{
                          e.valor_parcela.toLocaleString("pt-br", {
                            style: "currency",
                            currency: "BRL",
                          })
                        }}
                      </td>
                      <td class="text-center">
                        {{
                          new Date(e.data_vencimento).toLocaleDateString(
                            "pt-br"
                          )
                        }}
                      </td>
                      <td class="text-center">
                        {{ e.nome_plataforma }}
                      </td>
                      <td class="text-center">
                        {{ e.telefone }}
                      </td>
                      <td class="text-center">
                        <a
                          v-if="e.path_boleto"
                          :href="e.path_boleto"
                          target="_blank"
                          >Abrir boleto</a
                        >
                        <span v-else>Boleto Indisponível</span>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="12" class="text-center">Sem registros</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <small class="ml-4"
                >Mostrando {{ fastCobrancaRel.RangeStart }} até
                {{ fastCobrancaRel.RangeEnd }} de
                {{ fastCobrancaRel.TotalResults }}</small
              >
              <div
                class="col-12 d-flex text-center mt-4 justify-content-center"
              >
                <b-pagination
                  v-model="fastCobrancaRel.PageIndex"
                  :total-rows="fastCobrancaRel.TotalResults"
                  :per-page="fastCobrancaRel.PageSize"
                  @change="getCobrancasRel"
                ></b-pagination>
              </div>
            </div>
          </div>
        </transition>
        <!-- /Conteúdo Principal Relatório -->

        <!-- Detalhes - Ações -->

        <div class="mt-5 mb-5">
          <a
            id="btn_voltar_modo_admin"
            class="btn btn-red-hollow"
            :href="'/plataforma/' + $route.params.id_plataforma + '/tesouraria'"
            @click.prevent="
              $router.push(
                '/plataforma/' + $route.params.id_plataforma + '/tesouraria'
              )
            "
          >
            <b-icon-arrow-return-left />
            Voltar
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import methods from "@/methods";
import * as XLSX from "xlsx";

export default {
  name: "HomeInternoTesourariaRelatoriosCobrancaWhatsapp",
  mixins: [methods],
  data: function () {
    return {
      modalWidthSm: this.isMobile() ? "80%" : "30%",
      modalWidthMd: this.isMobile() ? "90%" : "50%",
      modalWidthLg: this.isMobile() ? "100%" : "90%",

      fastLinkClipboard: false,
      fastCobrancaRel: {
        loading: true,
        PageIndex: 1,
        PageSize: 20,
        RangeStart: 0,
        RangeEnd: 0,
        TotalResults: 0,
        filtro: [],
        paginacao: [],
        filtroEscolhido: {
          idPlataforma: "",
          IdsPlataformas: [],
          dataVencimentoStart: "",
          dataVencimentoEnd: "",
        },
        filtros: {
          plataformas: [],
        },
        selectedItems: [],
        selectAll: false,
      },
      tableData: [],
    };
  },
  mounted: function () {
    if (this.$route.params.id_plataforma) {
      this.getFastDependecias(this.$route.params.id_plataforma, "tesouraria")
        .then(() => {
          if (this.$store.state.fastPlataformaModulos.modulo_secretaria) {
            this.$store.state.fastCarregando = false;
            this.getFastHoraServidor().then(() => {
              this.getPlataformasFilhas();
              this.getCobrancasRel();
            });
          } else {
            this.$store.state.fastCarregando = false;
            this.exibeToasty(
              "Módulo não disponível para sua plataforma",
              "error"
            );
          }
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    }
  },
  methods: {
    getPlataformasFilhas() {
      //Pega plataformas filhas para o filtro
      this.promiseGetFastApi(
        "api/fast_plataforma/lista_filhas_novo",
        `id_plataforma=${this.$route.params.id_plataforma}`
      )
        .then((res) => {
          if (res.Data.length) {
            this.fastCobrancaRel.filtros.plataformas = res.Data;
          } else {
            this.fastCobrancaRel.filtros.plataformas =
              this.$route.params.id_plataforma;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async getCobrancasRel() {
      this.fastCobrancaRel.loading = true;
      let arrayPlataformasFiltro = [];
      this.fastCobrancaRel.filtroEscolhido.IdsPlataformas.forEach((el) => {
        arrayPlataformasFiltro.push(el.id);
      });
      this.$nextTick(() => {
        this.promiseGetFastApi(
          "api/fast_tesouraria_invoice_parcela/lista_cobranca_whatsapp",
          `PageIndex=${this.fastCobrancaRel.PageIndex}&PageSize=${
            this.fastCobrancaRel.PageSize
          }&IdPlataformaPrincipal=${this.$route.params.id_plataforma}${
            arrayPlataformasFiltro.length
              ? "&IdsPlataforma=" + arrayPlataformasFiltro
              : ""
          }${
            this.fastCobrancaRel.filtroEscolhido.dataVencimentoStart
              ? "&DataVencimentoStart=" +
                this.fastCobrancaRel.filtroEscolhido.dataVencimentoStart
              : ""
          }${
            this.fastCobrancaRel.filtroEscolhido.dataVencimentoEnd
              ? "&DataVencimentoEnd=" +
                this.fastCobrancaRel.filtroEscolhido.dataVencimentoEnd
              : ""
          }`
        )
          .then((res) => {
            if (res.Errors.length) {
              res.Errors.forEach((e) => {
                this.exibeToasty(e, "error");
              });
            }
            if (res.Data.length) {
              this.fastCobrancaRel.filtro = res.Data;
              this.fastCobrancaRel.RangeStart = res.RangeStart;
              this.fastCobrancaRel.RangeEnd = res.RangeEnd;
              this.fastCobrancaRel.TotalResults = res.TotalResults;
            } else {
              this.fastCobrancaRel.filtro = [];
            }
            this.fastCobrancaRel.loading = false;
          })
          .catch((e) => {
            console.log(e);
            this.exibeToasty(e, "error");
            this.fastCobrancaRel.loading = false;
          });
      });
    },
    adicionaPlataformaFiltro() {
      if (this.fastCobrancaRel.filtroEscolhido.idPlataforma === "todas") {
        this.fastCobrancaRel.filtros.plataformas.forEach((el) => {
          this.fastCobrancaRel.filtroEscolhido.IdsPlataformas.find(
            (p) => p.id === el.id_plataforma
          )
            ? this.exibeToasty(
                `Plataforma ${el.nome_plataforma} já adicionada.`,
                "error"
              )
            : this.fastCobrancaRel.filtroEscolhido.IdsPlataformas.push({
                id: el.id_plataforma,
                nome: el.nome_plataforma,
              });
        });
      } else {
        this.fastCobrancaRel.filtroEscolhido.IdsPlataformas.find(
          (p) =>
            p.id ===
            this.fastCobrancaRel.filtroEscolhido.idPlataforma.id_plataforma
        )
          ? this.exibeToasty(
              `Plataforma ${this.fastCobrancaRel.filtroEscolhido.idPlataforma.nome_plataforma} já adicionada.`,
              "error"
            )
          : this.fastCobrancaRel.filtroEscolhido.IdsPlataformas.push({
              id: this.fastCobrancaRel.filtroEscolhido.idPlataforma
                .id_plataforma,
              nome: this.fastCobrancaRel.filtroEscolhido.idPlataforma
                .nome_plataforma,
            });
      }
      this.fastCobrancaRel.filtroEscolhido.idPlataforma = "";
    },
    removePlataformaFiltro(id_plataforma) {
      let listaPlataformas =
        this.fastCobrancaRel.filtroEscolhido.IdsPlataformas.filter(
          (p) => p.id !== id_plataforma
        );
      this.fastCobrancaRel.filtroEscolhido.IdsPlataformas = listaPlataformas;
    },
    limpaFiltros() {
      this.fastCobrancaRel.filtroEscolhido.idPlataforma = "";
      this.fastCobrancaRel.filtroEscolhido.IdsPlataformas = [];
      this.fastCobrancaRel.filtroEscolhido.dataVencimentoStart = "";
      this.fastCobrancaRel.filtroEscolhido.dataVencimentoEnd = "";
      this.fastCobrancaRel.PageIndex = 1;

      this.getCobrancasRel();
    },
    async exportaPlanilha() {
      this.$store.state.fastCarregando = true;

      let arrayPlataformasFiltro =
        this.fastCobrancaRel.filtroEscolhido.IdsPlataformas.map((el) => el.id);
      let pageIndex = 1;
      let pageSize = 1000;
      let total = Infinity;
      let tableData = [
        [
          "#",
          "Nome",
          "Responsável",
          "Valor",
          "Vencimento",
          "Plataforma",
          "Telefone",
          "LinkBoleto",
        ],
      ];

      let response;

      try {
        do {
          response = await this.promiseGetFastApi(
            "api/fast_tesouraria_invoice_parcela/lista_cobranca_whatsapp",
            `PageIndex=${pageIndex}&PageSize=${pageSize}&IdPlataformaPrincipal=${this.$route.params.id_plataforma}` +
              `${
                arrayPlataformasFiltro.length
                  ? "&IdsPlataforma=" + arrayPlataformasFiltro
                  : ""
              }` +
              `${
                this.fastCobrancaRel.filtroEscolhido.dataVencimentoStart
                  ? "&DataVencimentoStart=" +
                    this.fastCobrancaRel.filtroEscolhido.dataVencimentoStart
                  : ""
              }` +
              `${
                this.fastCobrancaRel.filtroEscolhido.dataVencimentoEnd
                  ? "&DataVencimentoEnd=" +
                    this.fastCobrancaRel.filtroEscolhido.dataVencimentoEnd
                  : ""
              }`
          );

          if (response.Data.length === 0) break;

          if (response.Data) {
            response.Data.forEach((item) => {
              tableData.push([
                item.row_number || "",
                item.nome_aluno || "",
                item.nome_responsavel || "",
                item.valor_parcela || "",
                item.data_vencimento || "",
                item.nome_plataforma || "",
                item.telefone || "",
                item.path_boleto || "",
              ]);
            });
          }

          total = response.TotalResults;
          pageIndex++;
        } while ((pageIndex - 1) * pageSize < total);

        const worksheet = XLSX.utils.aoa_to_sheet(tableData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, "FastEAD - relatorio-cobranca.xlsx");
      } catch (error) {
        console.error(error);
        this.exibeToasty(error, "error");
      } finally {
        this.$store.state.fastCarregando = false;
      }
    },
    validaDataFiltro(input) {
      if (
        input === "I" &&
        this.fastCobrancaRel.filtroEscolhido.dataVencimentoEnd &&
        this.fastCobrancaRel.filtroEscolhido.dataVencimentoStart >
          this.fastCobrancaRel.filtroEscolhido.dataVencimentoEnd
      ) {
        this.exibeToasty(
          "A data inicial não pode ser maior do que a data final",
          "error"
        );
        setTimeout(() => {
          this.fastCobrancaRel.filtroEscolhido.dataVencimentoStart =
            this.fastCobrancaRel.filtroEscolhido.dataVencimentoEnd;
        }, "100");
      }

      if (
        input === "F" &&
        this.fastCobrancaRel.filtroEscolhido.dataVencimentoStart &&
        this.fastCobrancaRel.filtroEscolhido.dataVencimentoStart >
          this.fastCobrancaRel.filtroEscolhido.dataVencimentoEnd
      ) {
        this.exibeToasty(
          "A data final não pode ser menor do que a data inicial",
          "error"
        );
        setTimeout(() => {
          this.fastCobrancaRel.filtroEscolhido.dataVencimentoEnd =
            this.fastCobrancaRel.filtroEscolhido.dataVencimentoStart;
        }, "100");
      }
    },
  },
};
</script>

<style scope>
.container {
  max-width: 1280px;
}
.sugestao-lista {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: white;
  border: 1px solid #d8d8d8;
  border-top: none;
  padding: 0;
  margin: 0;
  z-index: 999;
}
.sugestao-item {
  list-style-type: none;
  padding: 3px 6px;
  cursor: pointer;
  color: rgb(95, 95, 95);
}
.sugestao-item:hover {
  transition: 0.3s;
  background-color: #e8e8e8;
}
.date-picker-filtro {
  text-align: center;
}
.btn-custom-primary {
  background-color: #007bff !important;
  color: #fff !important;
}
.btn-custom-outline-primary {
  background-color: #fff !important;
  color: #007bff !important;
  border: 1px solid #007bff !important;
}
.dropdown-menu {
  font-size: 12px;
}
</style>
